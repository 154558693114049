.usp-card {
  height: 220px;
  width: 300px;

  .title {
    z-index: 1;
    font-weight: 600;
    font-size: 16px;
  }

  .description {
    font-size: 12px;
    z-index: 1;
    text-wrap: 'pretty';
  }

  .usp-cover {
    margin-top: 18px;
    border-radius: 11px;
    background: white;
    padding: 5px;
  }

  .usp-cover-image {
    border-radius: 11px;
    width: 100%;
    height: 190px;
    object-fit: 'cover';
  }
}

.usp-card:hover {
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 4px 10px,
    rgba(0, 0, 0, 0.23) 0px 3px 3px;
  transition:
    box-shadow 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;

  .usp-cover {
    box-shadow:
      rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition:
      box-shadow 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out;
  }
}