.product-view {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;

  .course-container {
    width: 100%;
    height: 100%;
    margin-top: 5px;
    overflow-y: auto;

    .course-content {
      padding-left: 10px;
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .course-clip-art {
        rotate: 270deg;
        position: absolute;
        top: 67%;
        left: 0%;
      }

      .course-info {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 10px;
        width: 68%;

        .info-key {
          font-size: 22px;
        }

        .info-value {
          font-size: 14px;
          color: gray;
          text-wrap: pretty;
          text-align: justify;
          display: flex;
          flex-wrap: wrap;
          row-gap: 10px;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .label {
    color: white;
    font-size: 13px;
    font-weight: 600 !important;
  }

  .course-container::-webkit-scrollbar {
    width: 2px;
  }

  .enroll-button {
    position: relative;
    float: right;
    border-radius: 25px;
  }
}

@media (max-width: 768px) {
  .course-content .course-info {
    width: 100% !important;

    .ant-card .ant-card-head-title {
      width: 130px !important;
    }
  }
}