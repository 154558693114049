.ant-layout {
  .ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #ffffff;
    transition: all 0.4s ease-in-out, all 0.4s ease-in-out;

    .ant-menu {
      border: none;
      width: 100%;
    }
  }

  .ant-layout-sider-trigger {
    display: none;
  }
}

.profile-info-container {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 10px;

  .profile-info-widget-1,
  .profile-info-widget-2,
  .profile-info-widget-3 {
    width: 30%;
  }
}

.days-with .days-with-date:hover {
  background: #346ae0;
}

.left-aligned-title {
  text-align: left;
}

.ant-card {
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.ant-card:hover {
  transform: translateY(-5px);
}

.ant-card-head-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #222;
}

h4 {
  margin: 10px 0;
  color: #666;
}

button {
  margin-right: 12px;
  border-radius: 6px;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

button:hover {
  opacity: 0.9;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .left-aligned-title {
    width: 120%;
    margin: 0% !important;
    padding: 0% !important;
  }

  .menu-collapse-button {
    display: none !important;
  }

  .profile-info-container {
    width: 100%;

    .profile-info-widget-1,
    .profile-info-widget-2,
    .profile-info-widget-3 {
      width: 100%;
    }

    .profile-info-widget-2,
    .profile-info-widget-3 {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      padding: 0% !important;
      margin: 0% !important;

      .ant-card {
        width: 100%;
        padding: 0px;
        float: left;
        margin: 0% !important;
        margin-bottom: 10px !important;
      }
    }

    .profile-info-widget-3 {
      .ant-card .clip-art {
        left: 40% !important;
      }
    }
  }
}