.sine-wave-background {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #44424296;
}

.cover-image {
  width: 100%;
  height: 190px;
  object-fit: 'cover';
}

.enroll-button {
  z-index: 1;
  position: relative;
  float: right;
  border-radius: 25px;
}

.curved-card-container {
  width: 100%;

  .ant-card-body {
    padding: 4%;
  }
}

.coursecard {
  padding: 2px;
  width: 250px;
  height: 430px;
  position: 'relative';
  overflow: 'hidden';
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'space-between';

  .content,
  .course-basic,
  .course-description,
  .course-outcome {
    padding: 0px !important;
    margin-block: 5px;
  }

  .enrollment-row {
    position: absolute;
    bottom: 0;
    right: 3%;
    margin-bottom: 3%;
  }
}

.coursecard:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;

  .enroll-button {
    background-color: #2563eb;
    color: white;
    box-shadow:
      rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: all 0.4s ease,
  }

  .enroll-icon {
    font-size: 20px;
    transform: rotate(-45deg);
  }
}

.sine-wave-background path {
  border-radius: 22px;
  transform: translateY(50%);
}

@media (max-width: 768px) {
  .coursecard {
    width: 200px;
    height: 300px;
    overflow: 'scroll';

    .ant-card-cover {
      height: 50%;
    }

    .ant-card-cover img {
      height: 100%;
    }

    .course-outcome,
    .course-insights {
      display: none;
    }

    .content {
      .course-basic {
        padding: -10% !important;
        margin-block: 5px;

        .course-basic {
          display: flex;
        }

        .ant-tag {
          width: auto;
          font-size: 7px !important;
        }

      }
    }
  }
}