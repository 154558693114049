.hero {
  color: azure;
  height: 500px;
  background: linear-gradient(90deg,
      rgba(119, 172, 241, 1) 0%,
      rgba(73, 73, 180, 1) 14%,
      rgba(173, 225, 251, 1) 94%);
  padding: 50px;
  text-align: center;
}

.hero-text {
  align-items: self-end;

  .circle-container {
    position: relative;
    width: 300px;
    height: 500px;
  }

  .circle-container {
    position: absolute;
    bottom: 100px;
    transform: translateX(10%);
    z-index: 0;
  }

  .circle-container2 {
    position: absolute;
    left: 70%;
    bottom: -110px;
    transform: translateX(80%);
    z-index: 0;
  }

  .login-button,
  .register-button {
    border: none;
    border-radius: 25px;
    color: white;
    font-weight: 500;
    box-shadow:
      rgba(0, 0, 0, 0.19) 0px 4px 10px,
      rgba(0, 0, 0, 0.23) 0px 3px 3px;
  }

  .login-button {
    background: linear-gradient(90deg,
        rgba(173, 225, 251, 0.4374124649859944) 14%,
        rgba(73, 73, 180, 0.5466561624649859) 94%);
  }

  .register-button {
    background: linear-gradient(90deg,
        rgba(73, 73, 180, 0.5466561624649859) 14%,
        rgba(173, 225, 251, 0.4374124649859944) 94%);
  }

  .one {
    opacity: 0.3;
  }

  .three-container2 {
    opacity: 0.3;
  }

  .two {
    opacity: 0.6;
  }

  .three {
    opacity: 0.7;
  }
}

.hero-text h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 1.2em;
  max-width: 600px;
  margin-bottom: 40%;
}

.person {
  position: relative;
  z-index: 2;
  /* Ensure the person is above the shadow */
}

.shadow {
  opacity: 0;
  position: absolute;
  top: 100px;
  left: 0px;
  filter: blur(10px);
  z-index: 1;
}

.home-page-asset:hover .shadow {
  opacity: 0.3;
  transition:
    all 0.4s ease-in-out,
    all 0.4s ease-in-out;
}

.ant-drawer-content-wrapper {
  position: absolute;
  margin-top: 8%;
  margin-right: 5%;
  border-radius: 25px;
  right: 0;
  bottom: 0;
  height: 75%;
  width: 378px;
  overflow: -moz-hidden-unscrollable;
}

.courses,
.usp-body,
.testimonials {
  padding: 50px;
  text-align: center;
}



.card-slider-container,
.testimonial-body {
  width: 100%;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 10px;

  .course-parent-container {
    width: 100%;
    padding: 1%;
    .ant-card {
      margin-right: 5%;
    }
  }
}

.usp-cover-image {
  width: 80%;
  height: 190px;
  border-radius: 17px;
  object-fit: 'cover';
}

.card-slider-container::-webkit-scrollbar,
.testimonial-body::-webkit-scrollbar {
  display: none;
}

.arrow-button {
  position: absolute;
  top: 100%;
  transform: translateY(0%);
  z-index: 1;
}

.left {
  font-size: xx-large;
  border: none;
  color: black;
  background-color: rgba(0, 0, 0, 0);
}

.right {
  font-size: xx-large;
  border: none;
  color: black;
  background-color: rgba(0, 0, 0, 0);
  right: 10px;
}

.courses .content-headding,
.usp .content-headding,
.testimonials .content-headding {
  font-size: 22px;
  font-weight: 300;
  text-align: start;
}

.courses .content-headding {
  margin-bottom: 2%;
}

.usp .content-headding {
  margin-left: 4%;
}

.testimonials .content-headding {
  margin-top: 5%;
}

.usp-body {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  /* overflow-x: hidden; */
}

.footer {
  background: #f0f2f5;
  padding: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .hero {
    width: 80%;
    height: 12.5rem;

    .hero-content {
      position: relative;
      left: 10%;
      width: 100% !important;
    }

    .hero-text {
      align-items: self-end;

      .circle-container {
        position: absolute;
        top: 130px;
        left: -15%;
        width: 200px !important;
        height: 300px !important;

        svg {
          width: 200px;
          height: 200px;
        }
      }

      .patch-work {
        height: 15% !important;
        width: 15% !important;
        left: 90% !important;
        top: 3rem !important;
        bottom: 0 !important;
      }

      .circle-container2 {
        display: none;
      }

    }

    .hero-text h1 {
      font-size: 1em;
    }

    .hero-text p {
      font-size: 0.8em;
    }

    .person {
      height: 150px !important;
      width: 150px !important;
      position: absolute;
      top: 12.2rem;
      left: 0;
    }

    .shadow {
      height: 150px !important;
      width: 150px !important;
      position: absolute;
      top: 12.2rem;
      left: -1%;
    }
  }

  .courses,
  .usp-body,
  .testimonials {
    width: 100% !important;
    padding: 10px;
  }


  .testimonials {
    width: 100%;
    margin-bottom: 0px;
  }

  .card-slider-container {
    display: flex;

    .course-parent-container {
      width: 100%;
    }
  }

  .usp {
    width: 100%;
    height: 375px;
    margin-bottom: 10%;
    padding: 0% !important;
    scrollbar-width: none;

    .usp-body {
      height: 375px;
      overflow-y: hidden;
      overflow-x: auto;
      scrollbar-width: none;

      div {
        margin-right: 2%;
      }
    }
  }

}