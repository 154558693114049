body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom Scrollbar */
body::-webkit-scrollbar {
  width: 3px;
}

body::-webkit-scrollbar-track {
  background: #fafafa00;
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background: rgb(139, 137, 135);
  border-radius: 25px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
