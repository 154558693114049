.payment-card,
.payment-link-card {
  width: 340px;
  height: 200px;
  position: relative;
  z-index: 2;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 8px 16px,
    rgba(0, 0, 0, 0.23) 0px 3px 3px;

  .clip-art {
    rotate: 360deg;
    position: absolute;
    z-index: 0;
    left: 0%;
    bottom: 100%;
  }
}

.payment-link-card {
  .clip-art {
    animation: expandFade 4s infinite;
  }

  input,
  button {
    position: relative;
    z-index: 4;
  }

  h1 {
    font-size: 20px;
    font-weight: 300;
  }

  input {
    padding: 10px;
    height: 10px;
    border: 1px solid rgb(177, 177, 180);
    border-radius: 17px;
    margin: 5px;
  }

  input:focus {
    border-color: #346be0e0;
  }

  input:hover {
    border-color: #346be0e0;
  }

  button {
    float: right;
    color: aliceblue;
    background: #346be0e0;
    width: 80px;
    margin-right: 2%;
    border: 1px solid #2563eb;
    border-radius: 17px;
  }

  .clip-art {
    position: absolute;
    z-index: 0;
    bottom: 100%;
  }
}

.payment-card:hover {
  .heighlight {
    transform: scale(1.2, 1.2);
    transition:
      all 0.4s ease-in-out,
      all 0.4s ease-in-out;
  }

  .enroll-button {
    z-index: 1;
    background-color: #2563eb;
    color: white;
    box-shadow:
      rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition:
      all 0.4s ease-in-out,
      all 0.4s ease-in-out;
  }

  .enroll-icon {
    font-size: 20px;
    transform: rotate(-45deg);
    transition:
      all 0.4s ease-in-out,
      all 0.4s ease-in-out;
  }
}

@keyframes expandFade {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
